<!-- 내 문의사항 목록 페이지 -->
<template>
  <div>
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6>회관방문자 초대</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <!-- Route -->
            <route-bread-crumb></route-bread-crumb>
            <!-- End Route -->
          </nav>
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--6">
      <!--Tables-->
      <div class="row">
        <div class="col-xl">
          <div class="card">
            <!-- Search, New Button -->
            <div class="card-header border-0">
              <div class="row align-items-center">
                <div class="col"></div>

                <div class="col text-right">
                  <!-- New Button -->
                  <base-button type="primary" class="btn-sm" @click="handleAdd"
                    >+행사등록</base-button
                  >
                  <!-- End New Button -->
                </div>
              </div>
            </div>
            <!-- End Search, New Button -->
            <!-- Preview Modal -->
            <modal v-model:show="showPreviewModal" size="lg">
              <template v-slot:header>
                <h6 class="modal-title">
                  <div><p>행사 내용</p></div>
                </h6>
              </template>
              <card type="secondary" body-classes="px-lg-5">
                <form role="form">
                  <div class="form-group mt-2">
                    <base-input
                      type="date-local"
                      label="행사명"
                      name="title"
                      placeholder="행사명"
                      disabled
                      v-model="newData.title"
                    />
                  </div>
                  <div class="form-group mt-2">
                    <base-input
                      type="date-local"
                      label="행사장소"
                      name="place"
                      placeholder="행사장소"
                      disabled
                      v-model="newData.place_id"
                    />
                  </div>
                  <div class="form-group mt-2">
                    <base-input
                      type="date-local"
                      label="주관단체"
                      name="organization"
                      placeholder="주관단체"
                      disabled
                      v-model="newData.organization"
                    />
                  </div>
                  <div class="form-group mt-2">
                    <base-input
                      type="date-local"
                      label="주관부서"
                      name="dept_cd"
                      placeholder="주관부서"
                      disabled
                      v-model="newData.dept_cd"
                    />
                  </div>
                  <div class="form-group mt-2">
                    <base-input
                      type="datetime-local"
                      label="행사일시"
                      name="open_dt"
                      placeholder="행사일시"
                      disabled
                      v-model="newData.inv_dt"
                    />
                  </div>

                  <div class="form-group mt-2">
                    <label class="form-control-label">안내문자메세시</label>
                    <textarea
                      class="form-control"
                      disabled
                      v-model="newData.note"
                      rows="5"
                      placeholder=""
                    ></textarea>
                  </div>
                  <div class="form-group mt-2">
                    <base-input
                      type="text"
                      label="등록자"
                      name="writor"
                      disabled
                      placeholder="등록자"
                      v-model="newData.writor"
                    />
                  </div>

                  <div class="row">&nbsp;</div>
                </form>
              </card>
              <template v-slot:footer>
                <base-button
                  type="link"
                  class="ml-auto"
                  @click="showPreviewModal = false"
                  >Close</base-button
                >
              </template>
            </modal>
            <!-- End Preview Modal -->
            <!-- modal -->
            <edit-modal
              ref="editModal"
              @addDone="onAddDone"
              @editDone="onEditDone"
            />
            <!-- List -->

            <el-table
              class="table-responsive table"
              :data="tableData"
              :default-sort="{ prop: 'createdTime', order: 'descending' }"
              header-row-class-name="thead-light"
            >
              <el-table-column label="순서" sortable min-width="50px" prop="no">
              </el-table-column>

              <el-table-column
                label="실천장소"
                sortable
                min-width="150px"
                prop="place_id"
              >
              </el-table-column>
              <el-table-column
                label="행사일시"
                sortable
                min-width="150px"
                prop="inv_dt"
              >
              </el-table-column>
              <el-table-column
                label="행사명"
                sortable
                min-width="150px"
                prop="title"
              >
                <template v-slot="{ row }">
                  <span
                    class="row text-left mx-0 px-0"
                    @click.stop="showPreview(row)"
                  >
                    <div class="col font-weight-900 text-blue mx-0 px-0">
                      {{ row.title }}
                    </div>
                  </span>
                </template>
              </el-table-column>
              <el-table-column
                label="손님수"
                sortable
                min-width="100px"
                prop="guest_number"
              >
                <template v-slot="{ row }">
                  <span class="row text-left mx-0 px-0">
                    <div class="col font-weight-900 text-blue mx-0 px-0">
                      {{ row.guest_number }}
                      <base-button
                        class="edit mx-0 px-0"
                        type="primary"
                        size="sm"
                        @click.stop="goGuest(row)"
                      >
                        추가
                      </base-button>
                    </div>
                  </span>
                </template>
              </el-table-column>
              <el-table-column
                label="주관단체"
                sortable
                min-width="100px"
                prop="organization"
              >
              </el-table-column>
              <el-table-column
                label="주관부서"
                sortable
                min-width="100px"
                prop="dept_cd"
              >
              </el-table-column>
              <el-table-column
                label="발신번호"
                sortable
                min-width="130px"
                prop="callback"
              >
              </el-table-column>
              <el-table-column
                label="등록일"
                sortable
                min-width="100px"
                prop="createdDate"
              >
              </el-table-column>
              <el-table-column
                label="action"
                min-width="130px"
                align="right"
                v-slot="{ row }"
              >
                <div class="d-flex">
                  <base-button
                    class="edit"
                    type="warning"
                    size="sm"
                    @click.stop="handleEdit(row)"
                  >
                    수정
                  </base-button>
                  <base-button
                    class="remove btn-link text-white"
                    type="danger"
                    size="sm"
                    @click.stop="handleDelete(row)"
                  >
                    삭제
                  </base-button>
                </div>
              </el-table-column>
            </el-table>
            <!-- End List -->

            <!-- Pagination -->
            <div class="card-footer py-4 d-flex justify-content-center">
              <common-pagination
                @page-change="getDataFromApi"
                :rows-per-page="perPage"
                :total="total"
              />
            </div>
            <!-- End Pagination -->
          </div>
        </div>
      </div>
      <!--End tables-->
    </div>
  </div>
</template>

<script>
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import { ElTable, ElTableColumn } from "element-plus";
import EditModal from "./Board/EditModalEntry.vue";
import Modal from "@/components/Modal";
import * as api from "@/api";
import moment from "moment";

export default {
  // 컴포넌트 초기화
  components: {
    RouteBreadCrumb,
    [ElTable.name]: ElTable,
    [ElTableColumn.name]: ElTableColumn,
    EditModal,
    Modal,
  },
  // 데이터 객체 추가
  data() {
    return {
      tableData: [], // 레코드셋
      newData: {
        // 새 등록 데이터
        id: 0,
        question: "",
        questPart: "",
      },
      perPage: 20, // 페이지당 레코드 수
      total: 0, // 총 레코드 수
      showPreviewModal: false,
      edit: false,
    };
  },
  created() {
    // 페이지 생성 시 호출
    this.getDataFromApi();
  },

  // 함수 추가
  methods: {
    // api 호출
    getDataFromApi(page = 1) {
      // const url = `service/myinformation/entrywow?page=${page}`;
      // api.get(url).then((response) => {
      //   if (!api.isSuccess(response)) {
      //     return;
      //   }
      // });
      const url2 = `service/myinformation/entry?page=${page}`;
      api.get(url2).then((response) => {
        if (!api.isSuccess(response)) {
          return;
        }
        const data = response.data;
        const d = data.data;
        if (data.rows > 0) {
          for (const row of d) {
            row.inv_dt = moment(row.inv_dt).format("YYYY-MM-DD HH:mm");
            row.createdDate = moment(row.createdTime).format("YYYY-MM-DD");
            if (row.place_id == 221010) {
              row.place_id = "정토사회문화회관";
            } else if (row.place_id == 221020) {
              row.place_id = "용문/여주 부지";
            } else if (row.place_id == 221030) {
              row.place_id = "JTS 안산다문화센터";
            } else if (row.place_id == 221040) {
              row.place_id = "두북 정토수련원";
            } else if (row.place_id == 221050) {
              row.place_id = "정토미륵사";
            } else if (row.place_id == 221060) {
              row.place_id = "죽림정사";
            } else if (row.place_id == 221070) {
              row.place_id = "아도모례원";
            } else if (row.place_id == 221080) {
              row.place_id = "천룡사";
            } else if (row.place_id == 221090) {
              row.place_id = "부산 중생사";
            } else if (row.place_id == 221100) {
              row.place_id = "봉림사지";
            } else if (row.place_id == 221110) {
              row.place_id = "지리산 정토수련원";
            } else if (row.place_id == 221120) {
              row.place_id = "문경 정토수련원";
            } else if (row.place_id == 221130) {
              row.place_id = "봉화 정토수련원";
            }

            if (row.organization == 223001) {
              row.organization = "개인";
            } else if (row.organization == 223002) {
              row.organization = "정토회";
            } else if (row.organization == 223003) {
              row.organization = "정토법당";
            } else if (row.organization == 223004) {
              row.organization = "평화재단";
            } else if (row.organization == 223005) {
              row.organization = "한국제이티에스";
            } else if (row.organization == 223006) {
              row.organization = "에코붓다";
            } else if (row.organization == 223007) {
              row.organization = "좋은벗들";
            }
          }
        }
        this.tableData = d;
        this.total = data.total;
      });
    },
    // 등록 모달
    goGuest(row) {
      this.$router.push({
        name: "회관방문자 초대 - 손님등록",
        query: {
          suid: row.id,
          inv_id: row.inv_id,
        },
      });
    },
    // 등록 모달
    handleAdd() {
      this.$refs.editModal.handleAdd();
    },
    // 수정 모달
    handleEdit(row) {
      this.$refs.editModal.handleEdit(row);
    },
    // 등록 완료
    onAddDone() {
      this.getDataFromApi(1);
    },
    // 수정 완료
    onEditDone(row, data) {
      row.local = data.local;
      this.tableData = this.tableData.slice();
      this.getDataFromApi(1);
    },
    showPreview(row) {
      this.newData = row;
      this.showPreviewModal = true;
    },
    // 삭제하기
    handleDelete(row) {
      if (confirm("삭제하시겠습니까?")) {
        api.delete_(`service/myinformation/entrywow/${row.inv_id}`).then(() => {
          api.delete_(`service/myinformation/entry/${row.id}`).then(() => {
            this.getDataFromApi();
          });
        });
      }
    },
  },
};
</script>
<style scoped>
.clickable {
  cursor: pointer;
}
</style>
